import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62a9bdb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stepsBox" }
const _hoisted_2 = { class: "contentBox" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { style: {"padding":"20px 100px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_Base = _resolveComponent("Base")!
  const _component_CarryOut = _resolveComponent("CarryOut")!
  const _component_Done = _resolveComponent("Done")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_a_steps, {
          current: _ctx.current,
          "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current) = $event)),
          class: "steps",
          onChange: _ctx.changeSteps
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item) => {
              return (_openBlock(), _createBlock(_component_a_step, {
                key: item.title,
                title: item.title,
                disabled: _ctx.currentDone < item.num || _ctx.current== 2
              }, null, 8, ["title", "disabled"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["current", "onChange"])
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.current === 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "基础信息配置"))
          : _createCommentVNode("", true),
        (_ctx.current === 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, "执行内容配置"))
          : _createCommentVNode("", true),
        (_ctx.current === 2)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.current === 0)
          ? (_openBlock(), _createBlock(_component_Base, {
              key: 0,
              formState: _ctx.formState,
              onBaseDone: _ctx.baseDone
            }, null, 8, ["formState", "onBaseDone"]))
          : _createCommentVNode("", true),
        (_ctx.current === 1)
          ? (_openBlock(), _createBlock(_component_CarryOut, {
              key: 1,
              formState: _ctx.formState,
              onUpperLevel: _ctx.upperLevel,
              onSave: _ctx.save,
              onPublish: _ctx.publish
            }, null, 8, ["formState", "onUpperLevel", "onSave", "onPublish"]))
          : _createCommentVNode("", true),
        (_ctx.current === 2)
          ? (_openBlock(), _createBlock(_component_Done, {
              key: 2,
              formState: _ctx.formState
            }, null, 8, ["formState"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}