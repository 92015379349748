
import { defineComponent } from "vue";
import Base from "@/components/taskManagement/Base.vue";
import CarryOut from "@/components/taskManagement/CarryOut.vue";
import Done from "@/components/taskManagement/Done.vue";
import { getTask } from "@/api/taskManagement/taskManagement.js";
import moment from "moment";
export default defineComponent({
  components: {
    Base,
    CarryOut,
    Done,
  },
  data() {
    return {
      current: 0, //当前步骤条
      currentDone: 0, //已完成到第几步
      steps: [
        {
          title: "基础信息",
          num: 0,
        },
        {
          title: "执行内容配置",
          num: 1,
        },
        {
          title: "完成",
          num: 2,
        },
      ],
      //从路由传过来的id获取到的本条数据
      formState: {
        receiveAudit:0,
      },
    };
  },
  methods: {
    changeSteps(current) {
      //   this.current=0
    },
    async getData(id: any = false) {
      if (!id) {
        id = this.$route.params.id;
      }
      let res: any = await getTask(id, "UPDATE");
      this.formState = res.data.data;
      (this as any).formState.getEndTimePicker = moment(
        res.data.data.getEndTime,
        "YYYY-MM-DD HH:mm:ss"
      );
      (this as any).formState.rangePickerCarryOut = [
        moment(res.data.data.startTime, "YYYY-MM-DD HH:mm:ss"),
        moment(res.data.data.endTime, "YYYY-MM-DD HH:mm:ss"),
      ];
      (this as any).formState.extraList = [
        {
          name: "",
          value: "",
          code: "code0",
        },
        {
          name: "",
          value: "",
          code: "code1",
        },
      ];
      if ((this as any).formState.performUrlParam) {
        let array = JSON.parse((this as any).formState.performUrlParam);
        (this as any).formState.extraList = array.map((el, index) => {
          return {
            name: el.k,
            value: el.v,
            code: "code" + index,
          };
        });
      }
      if (res.data.data.taskTitle == null) {
        this.currentDone = 0;
      } else  {
        this.currentDone = 1;
      // } else if (res.data.data.taskStatus != "编辑中") {
      //   this.currentDone = 2;
      }
      console.log(this.currentDone );
      
    },
    //基础配置点击保存
    baseDone(id, next) {
      this.getData(id);
      if (next) {
        this.current++;
      }
    },
    //执行配置点击
    upperLevel() {
      this.current--;
    },
    save() {
      this.getData();
    },
    publish() {
      this.current++;
      this.getData();
    },
  },
  mounted() {
    if (this.$route.params.id != "new") {
      this.getData();
    }
  },
});
